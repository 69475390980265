import SegmentationCriterion from './SegmentationCriterion';

export enum Status {
  ERROR = 'Error',
  GENERATING_SEGMENT_DESCRIPTIONS = 'Generating Segment Descriptions',
  GENERATING_PERSONALIZATIONS = 'Generating Personalizations',
  PREPROCESSING_DATA = 'Preprocessing Data',
  PERFORMING_CLUSTERING = 'Performing Clustering',
  PROCESS_DONE = 'Process Done',
  STOPPED = 'Stopped'
}

export const STATUS_TO_STEP = {
  [Status.ERROR]: -1,
  [Status.PREPROCESSING_DATA]: 1,
  [Status.PERFORMING_CLUSTERING]: 2,
  [Status.GENERATING_SEGMENT_DESCRIPTIONS]: 3,
  [Status.GENERATING_PERSONALIZATIONS]: 5,
  [Status.PROCESS_DONE]: 6,
  [Status.STOPPED]: 0
}

export const TOTAL_STEPS = 6

export default interface Segmentation {
  id?: number;
  created_at?: string;
  name: string;
  segmentation_criteria?: SegmentationCriterion[];
  status: Status;
}