import { runInAction, makeObservable, observable, action } from 'mobx';
import BaseViewModel from './BaseViewModel';

export interface Variable {
  id?: number;
  name: string;
  description: string;
  prompt: string;
}

export default class VariablesPageViewModel extends BaseViewModel {
  variables: Variable[] = [];
  currentVariable: Variable = { name: '', description: '', prompt: '' };
  isLoading = false;
  error: string | null = null;
  openDialog = false;

  constructor() {
    super();
    makeObservable(this, {
      variables: observable,
      currentVariable: observable,
      openDialog: observable,
      fetchVariables: action,
      setCurrentVariable: action,
      saveVariable: action,
      deleteVariable: action,
      setOpenDialog: action,
      resetCurrentVariable: action,
    });
  }

  async fetchVariables() {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });

    try {
      const response = await this.api.get<Variable[]>('/variables');
      runInAction(() => {
        this.variables = response.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = `Failed to fetch variables. Please try again later. ${error}`;
        this.isLoading = false;
      });
    }
  }

  setCurrentVariable(variable: Variable | null) {
    this.currentVariable = variable || { name: '', description: '', prompt: '' };
  }

  async saveVariable() {
    try {
      if (this.currentVariable.id) {
        await this.api.put(`/variables/${this.currentVariable.id}`, this.currentVariable);
      } else {
        await this.api.post('/variables', this.currentVariable);
      }
      await this.fetchVariables();
      this.setOpenDialog(false);
    } catch (error) {
      runInAction(() => {
        this.error = `Error saving variable: ${error}`;
      });
     }
  }

  async deleteVariable(id: string) {
    try {
      await this.api.delete(`/variables/${id}`);
      await this.fetchVariables();
    } catch (error) {
      runInAction(() => {
        this.error = `Error deleting variable: ${error}`;
      });
    }
  }

  setOpenDialog(open: boolean) {
    this.openDialog = open;
    if (!open) {
      this.resetCurrentVariable();
    }
  }

  resetCurrentVariable() {
    this.currentVariable = { name: '', description: '', prompt: '' };
  }
}