import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

import { Scatter } from 'react-chartjs-2';
import { Spinner } from '@nextui-org/react';

import SegmentationConfigurationViewModel from '../viewModels/SegmentationConfigurationViewModel';
import SegmentationProcessingViewModel from '../viewModels/SegmentationProcessingViewModel';
import SegmentsComponent from '../components/SegmentsComponent';
import SegmentationCriteriaList from '../components/SegmentationCriteriaList';
import SegmentPersonalizationComponent from '../components/SegmentPersonalizationComponent';
import SegmentationCriterion from '../models/SegmentationCriterion';
import SegmentationResultViewModel from '../viewModels/SegmentationResultViewModel';
import { Segment } from '../models/Segment';
import { Status } from '../models/Segmentation';

const SegmentationPage = observer(() => {
  const { id, segmentId } = useParams();

  const navigate = useNavigate();
  const [viewModel] = useState(() => new SegmentationConfigurationViewModel());
  const [processingViewModel] = useState(() => new SegmentationProcessingViewModel());
  const [resultViewModel] = useState(() => new SegmentationResultViewModel(Number(id)));

  useEffect(() => {
    if (id && !isNaN(Number(id))) {
      viewModel.fetchSegmentation(Number(id));
      resultViewModel.setSegmentationId(Number(id));
    }
  }, [resultViewModel, viewModel, id]);

  useEffect(() => {
    if (viewModel.currentSegmentation && processingViewModel) {
      processingViewModel.setCurrentSegmentation(viewModel.currentSegmentation);
    }
  }, [viewModel.currentSegmentation, processingViewModel]);

  useEffect(() => {
    if (segmentId) {
      resultViewModel.setSelectedSegmentId(segmentId);
    }
  }, [resultViewModel, segmentId]);

  useEffect(() => {
    if (processingViewModel.currentSegmentation?.status === Status.PROCESS_DONE) {
      resultViewModel.fetchData();
    }
  }, [processingViewModel.currentSegmentation?.status, resultViewModel]);

  const handleCriteriaDelete = (segmentationCriterion: SegmentationCriterion) => {
    if (segmentationCriterion) {
      viewModel.deleteCriterion(segmentationCriterion);
    }
  };

  const handleCriteriaAdd = (id: string) => {
    if (viewModel.currentSegmentation?.id) {
      const newPriority = viewModel.currentSegmentation.segmentation_criteria?.length ?? 0;
      viewModel.addCriterion(id, newPriority, viewModel.currentSegmentation.id);
    }
  };

  const handleCriterionCreate = (name: string, prompt: string) => {
    if (viewModel.currentSegmentation?.id) {
      const newPriority = viewModel.currentSegmentation.segmentation_criteria?.length ?? 0;
      viewModel.createCriterion(name, prompt, newPriority, viewModel.currentSegmentation.id);
    }
  };

  const handlePreviewStart = () => {
    //  resultViewModel.clearData();
    //  processingViewModel.startPreview();
  };

  const handleProcessStart = () => {
    resultViewModel.clearData();
    processingViewModel.startProcessing();
  };

  const handleStop = () => {
    //  processingViewModel.stopProcessing();
  };

  const { availableCriteria, currentSegmentation } = viewModel;
  const { variablesWithPersonalizations, segments } = resultViewModel;

  // Add these options for the scatter plot
  const options = {
    animation: false,
    parsing: false,
    scales: {
      x: {
        min: -1.1,
        max: 1.1,
        ticks: {
          display: false,
        }
      },
      y: {
        min: -1.1,
        max: 1.1,
        title: {
          display: false,
        },
        ticks: {
          display: false,
        }
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      tooltip: {
        enabled: false
      }
    }
  };

  return (
    <div className='flex-1 p-4 overflow-y-auto'>
      <div className='grid grid-rows-2'>
        <div className='row-span-1 h-[400px]'>
          <div className='grid grid-cols-3 h-[400px]'>
            <div className='col-span-1 flex flex-col h-[400px]'>
              {currentSegmentation && (
                <SegmentationCriteriaList
                  availableCriteria={availableCriteria}
                  segmentation={currentSegmentation}
                  status={currentSegmentation.status}
                  onCriteriaAdd={handleCriteriaAdd}
                  onCriterionCreate={handleCriterionCreate}
                  onCriteriaDelete={handleCriteriaDelete}
                  onPreviewStart={handlePreviewStart}
                  onProcessStart={handleProcessStart}
                  onStop={handleStop}
                />
              )}
            </div>
            <div className='col-span-1 flex flex-col h-[400px]'>
              {currentSegmentation && (
                <SegmentsComponent
                  onSelectedSegmentChange={(segment: Segment) => {
                    navigate(`/segmentations/${id}/${segment.id}`);
                  }}
                  segments={segments}
                  selectedSegmentId={segmentId}
                  status={currentSegmentation.status}
                  isLoading={resultViewModel.isLoading}
                  error={resultViewModel.error}
                />
              )}
            </div>
            <div className='col-span-1 flex flex-col h-[400px]'>
              <h1 className='text-2xl font-bold ml-2'>Cluster Visualization</h1>
              {resultViewModel.isLoading && (
                <div className='flex-1 flex justify-center items-center'>
                  <Spinner />
                </div>
              )}
              {!resultViewModel.isLoading && (
                <Scatter
                  height={200}
                  data={{ datasets: resultViewModel.chartSeries }}
                  options={options} />
              )}
            </div>
          </div>
        </div>
        <div className='row-span-1'>
          {segments && (
            <SegmentPersonalizationComponent
              selectedSegmentId={segmentId}
              variablesWithPersonalizations={variablesWithPersonalizations}
              isLoading={resultViewModel.isLoading}
              error={resultViewModel.error}
              status={currentSegmentation?.status}
            />
          )}
        </div>
      </div>
    </div >
  );
});

export default SegmentationPage;