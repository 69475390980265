export enum UserRoleType {
  ADMIN = "ADMIN",
  USER = "USER"
}

export interface UserSystemRole {
  created_at: string;
  user_role_type: UserRoleType;
  updated_at: string;
  user_id: number;
}
