import { runInAction, makeObservable, observable, action } from 'mobx';
import BaseViewModel from './BaseViewModel';
import { User } from '../models/User';
import { Tenant } from '../models/UserTenant';
import { UserRoleType } from '../models/UserSystemRole';

interface UserTenantUpdateSchema {
  is_active: boolean;
  tenant_id: number;
}

interface UserSystemRoleUpdateSchema {
  user_role_type: 'ADMIN' | 'USER';
}

export interface UserUpdate {
  id: number;
  user_tenants: UserTenantUpdateSchema[];
  user_system_roles: UserSystemRoleUpdateSchema[];
}

export default class UsersPageViewModel extends BaseViewModel {
  tenants: Tenant[] = [];
  users: User[] = [];

  constructor() {
    super();
    makeObservable(this, {
      fetchData: action,
      updateUser: action,
      users: observable,
    });
    this.fetchData();
  }

  async fetchData() {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
      this.users = [];
    });
    try {
      const [usersResponse, tenantsResponse] = await Promise.all([
        this.api.get<User[]>('/admin/users'),
        this.api.get<Tenant[]>('/admin/tenants')
      ]);
      runInAction(() => {
        this.users = usersResponse.data;
        this.tenants = tenantsResponse.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch users. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  async updateUser(user: UserUpdate) {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      await this.api.patch<User>(`/admin/users/${user.id}`, user);
      this.fetchData();
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to update user. Please try again later.';
        this.isLoading = false;
      });
    }
  }
}
