import { runInAction, makeObservable, observable, action } from 'mobx';

import BaseViewModel from './BaseViewModel';
import AuthStore from '../stores/AuthStore';
import Segmentation from '../models/Segmentation';
import { Status } from '../models/Segmentation';

export default class SegmentationProcessingViewModel extends BaseViewModel {
  currentSegmentation?: Segmentation = undefined;
  task_id?: string = undefined;

  isLoading = false;
  error: string | null = null;

  private websocket: WebSocket | null = null;

  constructor() {
    super();
    makeObservable(this, {
      currentSegmentation: observable,
      setCurrentSegmentation: action,
      startPreview: action,
      startProcessing: action,
      stopProcessing: action,
    });
    this.initializeWebSocket();
  }

  setCurrentSegmentation = (segmentation: Segmentation) => {
    this.currentSegmentation = segmentation;
  }

  startPreview = () => {
    // TODO: implementation missing
  }

  startProcessing = async () => {
    if (!this.currentSegmentation) {
      return;
    }
    try {
      const response = await this.api.post<{ task_id: string }>(`/segmentations/${this.currentSegmentation.id}/run`);
      this.task_id = response.data.task_id;
    } catch (err) {
      this.error = `Failed to start ${err}`;
    }
  }

  stopProcessing = () => {
    // this.createCommand(CommandAction.STOP);
  }

  private initializeWebSocket = () => {
    if (this.websocket) {
      return;
    }
    const url = process.env.REACT_APP_WSS_URL || 'ws://localhost:4000';
    this.websocket = new WebSocket(`${url}/ws/segmentations?authorization=${AuthStore.token}`);

    this.websocket.onmessage = (event) => {
      interface StatusUpdateMessage {
        segmentation_id: number;
        status: Status;
      }
      const data = JSON.parse(event.data) as StatusUpdateMessage;
      runInAction(() => {
        if (this.currentSegmentation && this.currentSegmentation.id === data.segmentation_id) {
          this.currentSegmentation.status = data.status;
        }
      });
    };

    this.websocket.onerror = (error) => {
      runInAction(() => {
        this.error = `WebSocket error: ${error}`;
      });
    };

    this.websocket.onclose = () => {
      this.websocket = null;
    };
  }
}