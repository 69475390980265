import { runInAction, makeObservable, action, computed } from 'mobx';

import AuthStore from '../stores/AuthStore';
import UserProfileStore from '../stores/UserProfileStore';
import BaseViewModel from './BaseViewModel';
import { UserProfile } from '../models/UserProfile';

export class UserProfileViewModel extends BaseViewModel {
  constructor() {
    super();
    this.isLoading = true;
    makeObservable(this, {
      profile: computed,
      fetchProfile: action,
    });
  }


  get profile() {
    return UserProfileStore.profile;
  }

  async fetchProfile() {
    if (!AuthStore.isAuthenticated) {
      runInAction(() => {
        this.error = null;
        this.isLoading = false;
      });
      return;
    }
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });

    try {
      const response = await this.api.get<UserProfile>('/users/me');
      runInAction(() => {
        UserProfileStore.setProfile(response.data);
        this.isLoading = false;
      });
      return response;
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch profile. Please try again later.';
        this.isLoading = false;
      });
      throw error;
    }
  }
}