import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { NextUIProvider, Spinner } from '@nextui-org/react';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import { observer } from 'mobx-react-lite';
import toast, { Toaster } from "react-hot-toast";

import AppLayout from './components/AppLayout';
import { routes } from './routes';
import OAuthCallbackPage from './pages/OAuthCallbackPage';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import AuthStore from './stores/AuthStore';
import { UserProfileViewModel } from './viewModels/UserProfileViewModel';
import { useEffect, useState } from 'react';

const AppContent = observer(() => {
  const { isDark } = useTheme();
  const [userProfileViewModel] = useState(() => new UserProfileViewModel());
  const [authStore] = useState(() => AuthStore);

  const { isLoading, error, profile } = userProfileViewModel;

  useEffect(() => {
    userProfileViewModel.fetchProfile()
      .catch(() => {
        authStore.clearToken();
        toast.error('Session expired. Please login again.');
      });
  }, [authStore, userProfileViewModel]);

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">
      <Spinner label="Loading..." />
    </div>;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Toaster />
        <div className="text-red-500">An error occurred: {error}</div>
      </div>
    );
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <AppLayout />,
      children: [
        {
          path: '/',
          element: <ProtectedRoute><DashboardPage /></ProtectedRoute>,
        },
        {
          path: '/oauth/callback',
          element: <OAuthCallbackPage />,
        },
        ...routes.filter(route => route.requiredRole ? profile?.system_roles.some(role => role.user_role_type === route.requiredRole) : true).map(route => ({
          path: route.path,
          element: <ProtectedRoute>{route.element}</ProtectedRoute>,
        })),
        {
          path: '/login',
          element: <LoginPage />,
        },
      ],
    },
  ]);

  return (
    <NextUIProvider>
      <main className={`${isDark ? 'dark' : 'light'} text-foreground bg-background`}>
        <RouterProvider router={router} />
      </main>
    </NextUIProvider>
  );
});

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

export default App;