import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Spinner,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button
} from "@nextui-org/react";
import { ColumnSize } from "@react-types/table";
import PreferencesPageViewModel, { UserTenant } from '../viewModels/PreferencesPageViewModel';


type ColumnKey = 'tenant_name' | 'is_active' | 'actions';

const columns: { key: ColumnKey, label: string, width: ColumnSize | null }[] = [
  {
    key: "tenant_name",
    label: "Tenant name",
    width: null
  },
  {
    key: "is_active",
    label: "Active",
    width: 80
  },
  {
    key: "actions",
    label: "Actions",
    width: 80
  },
];

const TenantSettingsPage = observer(() => {
  const [viewModel] = useState(() => new PreferencesPageViewModel());

  const renderCell = useCallback((userTenant: UserTenant, columnKey: ColumnKey) => {
    switch (columnKey) {
      case "tenant_name":
        return (
          <span className="text-sm">{userTenant.tenant.name}</span>
        );
      case "is_active":
        return (
          <span className="text-sm">{userTenant.is_active ? 'Yes' : 'No'}</span>
        );
      case "actions":
        if (!userTenant.is_active) {
          return (
            <Button size="sm" onClick={() => viewModel.activateUserTenant(userTenant.id)}>Activate</Button>
          );
        }
        break;
      default:
        return "nothing";
    }
  }, []);

  if (viewModel.isLoading) {
    <div className='w-full flex items-center justify-center'>
      <Spinner />
    </div>
  }

  if (viewModel.error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className='text-danger'>{viewModel.error}</p>
      </div>
    );
  }

  return (
    <div className='flex-1 p-8 max-w-[800px] mx-auto'>
      <div className='flex flex-col h-full'>
        <Table
          aria-label="Tenants table with dynamic content"
          layout='fixed'
        >
          <TableHeader columns={columns}>
            {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody
            items={viewModel.userTenants.slice().sort((a, b) => a.tenant.name.localeCompare(b.tenant.name))}
            emptyContent="No tenants found."
          >
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => {
                  return <TableCell>{renderCell(item, columnKey as ColumnKey)}</TableCell>
                }}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
});

export default TenantSettingsPage;
