import { makeObservable, observable } from 'mobx';
import axios, { AxiosInstance } from 'axios';
import AuthStore from '../stores/AuthStore';

export default class BaseViewModel {
  protected api: AxiosInstance;

  isLoading = false;
  error: string | null = null;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      error: observable,
    });

    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL || 'http://localhost:4000',
    });
    console.log('Axios baseURL:', this.api.defaults.baseURL);

    this.api.interceptors.request.use((config) => {
      console.log('Full request URL:', config.baseURL + config.url);
      if (AuthStore.token) {
        config.headers['authorization'] = `Bearer ${AuthStore.token}`;
      }
      return config;
    });
  }
}
