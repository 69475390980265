import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  Checkbox,
  Chip,
  ChipProps,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
} from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Input } from "@nextui-org/react";

import UsersViewModel, { UserUpdate } from '../../viewModels/UsersViewModel';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import { EditIcon } from '../../assets/icons/EditIcon';
import toast, { Toaster } from 'react-hot-toast';
import { User } from '../../models/User';
import { getTypedFormData } from '../../utils/TypeFormData';
import { UserRoleType } from '../../models/UserSystemRole';
// Define column structure type
type Column = {
  key: keyof User | 'actions' | 'role';
  label: string;
  width?: number | null;
}

// Type-safe columns definition
const columns: Column[] = [
  {
    key: "email",
    label: "Email",
    width: null,
  },
  {
    key: "role",
    label: "Role",
    width: 100,
  },
  {
    key: "actions",
    label: "Actions",
    width: 100,
  }
];

const statusColorMap: Record<string, ChipProps["color"]> = {
  admin: "success",
  user: "secondary",
};

// Add this interface above the component
interface UserFormData {
  email: string;
  is_admin: string;
  active_tenant: string;
  [key: string]: string; // for dynamic tenant access checkboxes
}

const UsersPage = observer(() => {
  const [viewModel] = useState(() => new UsersViewModel());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const renderCell = useCallback((user: User, columnKey: Column['key']) => {
    switch (columnKey) {
      case "email":
        return <p>{user.email}</p>;
      case "role":
        return (<Chip className="capitalize" color={statusColorMap[user.user_system_roles.some(role => role.user_role_type === UserRoleType.ADMIN) ? 'admin' : 'user']} size="sm" variant="flat">
          {user.user_system_roles.some(role => role.user_role_type === UserRoleType.ADMIN) ? 'Admin' : 'User'}
        </Chip>)
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="Edit">
              <span onClick={() => {
                setUser(user);
                setIsModalOpen(true)
              }} className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <EditIcon />
              </span>
            </Tooltip>
            {/* <Tooltip color="danger" content="Delete">
              <span onClick={() => {
                setIsModalOpen(true);
              }} className="text-lg text-danger cursor-pointer active:opacity-50">
                <DeleteIcon />
              </span>
            </Tooltip> */}
          </div>
        );
      default:
        return null;
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (!user) return;
    event.preventDefault();
    const formData = getTypedFormData<UserFormData>(event.currentTarget);

    // Get selected tenant IDs from checkboxes
    const selectedTenants = viewModel.tenants
      .filter(tenant => formData.get(`tenant_access_${tenant.id}`) === 'true')
      .map(tenant => ({
        tenant_id: tenant.id,
        is_active: tenant.id.toString() === formData.get('active_tenant')
      }));

    // Prepare user update data
    const userData: UserUpdate = {
      id: user.id,
      user_tenants: selectedTenants,
      user_system_roles: [{
        user_role_type: formData.get('is_admin') === 'true' ? 'ADMIN' : 'USER'
      }]
    };


    viewModel.updateUser(userData)
      .then(() => {
        setIsModalOpen(false);
        toast.success('User updated');
      })
  };

  const { users } = viewModel;

  if (viewModel.error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className='text-danger'>{viewModel.error}</p>
      </div>
    );
  }

  return (
    <div className='flex-1 p-8 max-w-[800px] mx-auto'>
      <div className='flex flex-col h-full'>
        <Toaster />
        <Modal
          isOpen={isModalOpen}
          onOpenChange={() => setIsModalOpen(false)}
          placement="top-center"
        >
          <ModalContent>
            {(onClose) => (
              <form onSubmit={handleSubmit}>
                <ModalHeader className="flex flex-col gap-1">User</ModalHeader>
                <ModalBody>
                  <Input
                    defaultValue={user?.email}
                    disabled
                    id="email"
                    label="Email"
                    type="text"
                  />
                  <div className="flex flex-col gap-2">
                    <p className="text-sm font-medium">Tenant Access</p>
                    {viewModel.tenants.sort((a, b) => a.name.localeCompare(b.name)).map(tenant => (
                      <div key={tenant.id} className="flex items-center gap-4 p-2 rounded-md border border-default-200">
                        <Checkbox
                          defaultSelected={user?.user_tenants.some(ut => ut.tenant.id === tenant.id)}
                          id={`tenant_access_${tenant.id}`}
                          name={`tenant_access_${tenant.id}`}
                          value="true"
                        />
                        <span className="flex-grow">{tenant.name}</span>
                        <input
                          className="ml-auto"
                          defaultChecked={user?.user_tenants.some(ut => ut.tenant.id === tenant.id && ut.is_active)}
                          id={`active_tenant`}
                          name="active_tenant"
                          type="radio"
                          value={tenant.id}
                        />
                      </div>
                    ))}
                    <p className="text-xs text-default-400">Check to grant access, select radio button to set active tenant</p>
                  </div>
                  <Checkbox
                    defaultSelected={user?.user_system_roles.some(role => role.user_role_type === UserRoleType.ADMIN)}
                    name="is_admin"
                    value="true"
                  >Is admin</Checkbox>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="flat" onPress={onClose}>
                    Close
                  </Button>
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </ModalFooter>
              </form>
            )}
          </ModalContent>
        </Modal>
        <h1 className='text-2xl font-bold'>Users</h1>
        <Table
          aria-label="Recipients table"
          isStriped
          isHeaderSticky
          className="h-full"
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn key={column.key} width={column.width}>
                {column.label}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody
            loadingContent={<Spinner />}
            isLoading={viewModel.isLoading}
            items={users.slice().sort((a, b) => a.email.localeCompare(b.email))}
          >
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => <TableCell>{renderCell(item, columnKey as Column['key'])}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div >
  )
});

export default UsersPage;
