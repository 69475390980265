import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow
} from '@nextui-org/react';
import { ColumnSize } from "@react-types/table";
import { Segment } from '../models/Segment';
import { Status } from '../models/Segmentation';

interface SegmentsComponentProps {
  onSelectedSegmentChange: (segment: Segment) => void;
  segments?: Segment[];
  selectedSegmentId?: string;
  status: Status;
  isLoading: boolean;
  error: string | null;
}

type SegmentsColumnKey = 'name' | 'size' | 'expected_outcome';

const segmentsTableColumns: { key: SegmentsColumnKey, label: string, width: ColumnSize | null }[] = [
  { key: 'name', label: 'Name', width: null },
  { key: 'size', label: 'Size', width: 80 },
  { key: 'expected_outcome', label: 'Expected Outcome', width: 140 }
];

const SegmentsComponent = observer((props: SegmentsComponentProps) => {
  const { segments, selectedSegmentId, status, isLoading, error, onSelectedSegmentChange } = props;

  const renderSegmentCell = useCallback((segment: Segment, columnKey: SegmentsColumnKey) => {
    switch (columnKey) {
      case 'name':
        return (
          <div className='flex flex-col'>
            <span className='text-sm'>{segment.name}</span>
          </div>
        )
      case 'size':
        return (
          <div className='flex flex-col'>
            <span className='text-sm'>{segment.size}</span>
          </div>
        )
      case 'expected_outcome':
        return (
          <div className='flex flex-col'>
            <span className='text-sm'>{segment.expected_outcome?.toFixed(2)}</span>
          </div>
        )
      default:
        return "nothing"
    }
  }, []);

  if (error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className="text-danger">{error}</p>
      </div>
    );
  }

  return (
    <>
      <h1 className='text-2xl font-bold ml-2'>Segments</h1>
      {(status !== Status.PROCESS_DONE && status !== Status.ERROR && status !== Status.STOPPED) || isLoading && (
        <div className='flex-1 flex justify-center items-center'>
          <Spinner />
        </div>
      )}
      {status === Status.PROCESS_DONE && !isLoading && (
        <Table
          aria-label="Segmentation control table with dynamic content"
          classNames={{ base: "overflow-auto p-2" }}
          layout='fixed'
          isHeaderSticky
          onSelectionChange={(keys) => {
            const selectedKey = Array.from(keys)[0];
            const selectedSegment = segments?.find(seg => seg.id?.toString() === selectedKey?.toString());
            if (selectedSegment) {
              onSelectedSegmentChange(selectedSegment);
            }
          }}
          selectedKeys={[selectedSegmentId || '']}
          selectionMode="single"
          selectionBehavior="replace"
        >
          <TableHeader columns={segmentsTableColumns}>
            {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody items={segments}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => {
                  return <TableCell>{renderSegmentCell(item, columnKey as SegmentsColumnKey)}</TableCell>
                }}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
});

export default SegmentsComponent;