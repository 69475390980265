import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow
} from '@nextui-org/react';
import { ColumnSize } from "@react-types/table";
import { Status } from '../models/Segmentation';
import { VariableWithPersonalizations } from '../viewModels/SegmentationResultViewModel';

interface SegmentPersonalizationComponentProps {
  selectedSegmentId?: string;
  status: Status;
  variablesWithPersonalizations?: VariableWithPersonalizations[];
  isLoading: boolean;
  error: string | null;
}

type ColumnKey = 'name' | 'result';
const tableColumns: { key: ColumnKey, label: string, width: ColumnSize | null }[] = [
  { key: 'name', label: 'Name', width: 200 },
  { key: 'result', label: 'Result', width: null }
];
const SegmentPersonalizationComponent = observer((props: SegmentPersonalizationComponentProps) => {
  const { selectedSegmentId, status, isLoading, error, variablesWithPersonalizations } = props;

  const renderCell = useCallback((variable: VariableWithPersonalizations, columnKey: ColumnKey) => {
    switch (columnKey) {
      case 'name':
        return (
          <div className='flex flex-col'>
            <span className='text-sm'>{variable.name}</span>
          </div>
        )
      case 'result':
        return (
          <div className='flex flex-col'>
            <span className='text-sm'>{variable.personalizations.map(p => p.value).join(', ')}</span>
          </div>
        )
        break;
      default:
        return "nothing"
    }
  }, []);

  if (error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className="text-danger">{error}</p>
      </div>
    );
  }

  return (
    <div className='flex flex-col h-full ml-2'>
      <h1 className='text-2xl font-bold'>Personalizations</h1>
      {(status !== Status.PROCESS_DONE && status !== Status.ERROR && status !== Status.STOPPED) || isLoading && (
        <div className='flex-1 flex justify-center items-center'>
          <Spinner />
        </div>
      )}
      {status === Status.PROCESS_DONE && !isLoading && (
        <Table
          aria-label="Segmentation control table with dynamic content"
          classNames={{ base: "mt-2" }}
          layout='fixed'
        >
          <TableHeader columns={tableColumns}>
            {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody
            items={variablesWithPersonalizations}
            emptyContent={!selectedSegmentId ? "Please select a segment first." : "No variables to personalize for this segment."}
          >
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => {
                  return <TableCell>{renderCell(item, columnKey as ColumnKey)}</TableCell>
                }}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
});

export default SegmentPersonalizationComponent;