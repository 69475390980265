import { action, makeObservable, observable } from 'mobx';
import { UserProfile } from '../models/UserProfile';

class UserProfileStore {
  profile: UserProfile | null = null;

  constructor() {
    makeObservable(this, {
      profile: observable,
      setProfile: action,
      clearProfile: action,
    });
  }

  setProfile(profile: UserProfile) {
    this.profile = profile;
  }

  clearProfile() {
    this.profile = null;
  }

  get hasAdminRole() {
    return this.profile?.system_roles.some(role => role.role === 'admin') ?? false;
  }
}

export default new UserProfileStore();