import { runInAction, makeObservable, observable, action } from 'mobx';
import BaseViewModel from './BaseViewModel';
import Criterion from '../models/Criterion';

export default class CriteriaPageViewModel extends BaseViewModel {
  criteria: Criterion[] = [];
  currentCriterion: Criterion = { id: '', name: '', prompt: '' };
  isLoading = false;
  error: string | null = null;
  isDialogOpen = false;
  isEditing = false;
  snackbar: { open: boolean; message: string } = { open: false, message: '' };

  constructor() {
    super();
    makeObservable(this, {
      criteria: observable,
      currentCriterion: observable,
      isDialogOpen: observable,
      isEditing: observable,
      snackbar: observable,
      fetchCriteria: action,
      openDialog: action,
      closeDialog: action,
      setCurrentCriterion: action,
      saveCriterion: action,
      deleteCriterion: action,
      setSnackbar: action,
    });
  }

  async fetchCriteria() {
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });

    try {
      const response = await this.api.get<Criterion[]>('/criteria');
      runInAction(() => {
        this.criteria = response.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch criteria. Please try again later.';
        this.isLoading = false;
      });
    }
  }

  openDialog(criterion: Criterion | null = null) {
    if (criterion) {
      this.currentCriterion = { ...criterion };
      this.isEditing = true;
    } else {
      this.currentCriterion = { id: '', name: '', prompt: '' };
      this.isEditing = false;
    }
    this.isDialogOpen = true;
  }

  closeDialog() {
    this.isDialogOpen = false;
    this.currentCriterion = { id: '', name: '', prompt: '' };
    this.isEditing = false;
  }

  setCurrentCriterion(field: string, value: string) {
    this.currentCriterion = { ...this.currentCriterion, [field]: value };
  }

  async saveCriterion() {
    try {
      if (this.isEditing) {
        await this.api.put(`/criteria/${this.currentCriterion.id}`, this.currentCriterion);
        this.setSnackbar(true, 'Criterion updated successfully');
      } else {
        await this.api.post('/criteria', this.currentCriterion);
        this.setSnackbar(true, 'Criterion added successfully');
      }
      await this.fetchCriteria();
      this.closeDialog();
    } catch (error) {
      this.setSnackbar(true, 'Error saving criterion');
    }
  }

  async deleteCriterion(id: string) {
    try {
      await this.api.delete(`/criteria/${id}`);
      await this.fetchCriteria();
      this.setSnackbar(true, 'Criterion deleted successfully');
    } catch (error) {
      this.setSnackbar(true, 'Error deleting criterion');
    }
  }

  setSnackbar(open: boolean, message = '') {
    this.snackbar = { open, message };
  }
}